/* LoadingScreen.css */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #282c34; /* Цвет фона */
    z-index: 9999; /* Убедитесь, что загрузочный экран выше остальных элементов */
}

.loading-animation {
    width: 100px;
    height: 100px;
    border: 8px solid #ffffff; /* Цвет ободка */
    border-top: 8px solid #61dafb; /* Цвет верхней части */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Добавляем анимацию вращения */
}

.loading-text {
    margin-top: 20px;
    font-size: 24px; /* Размер шрифта */
    font-weight: bold;
    color: #ffffff; /* Цвет текста */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.3); /* Тень для текста */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
